import React from "react";
import Layout from "../components/layout";
// import Seo from "../components/seo";
import HeaderAd from "../components/ads/header-ad";

// markup
const Success = () => {
  return (
    <Layout> 
      <div>
        <HeaderAd />
      </div>
      <div className={"container mx-auto px-4  pb-6 py-6"}>
        <h1 className={"text-4xl font-bold mb-10"}>Thanks!</h1>
        <div className={"w-full p-4 lg:p-20 bg-white"}>
          <p>Your contribution will help a lot!</p>
        </div>
      </div>
    </Layout>
  );
};

export default Success;
